export const HOST = (() => {
  if (process.env.NODE_ENV === "development") {
    //开发调试
    // return "http://192.168.31.8:8006"
    // return "http://192.168.31.12:6000"
    // return "http://1.14.156.250:7000"
    // return 'http://192.168.31.65:8001'
    // return 'http://192.168.31.55:8000'
  } 
  //线上
  return "https://api-crm.jialeijob.com"
})()

export const BASE_URL = `${HOST}/api`