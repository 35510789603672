import { lazy } from "react";
import type { routeParams } from "../../utils/createRoutes";
import MyResume from "../pages/MyResume";
import MyResumeDetail from "../pages/MyResumeDetail";
import { addRequireAuthForObject } from "../utils/utils";

const CrmApp = lazy(() => import("../index"));
const Home = lazy(() => import("../pages/Home/index"));
const Projects = lazy(() => import("../pages/Projects/index"));
const Resume = lazy(() => import("../pages/Resume/index"));
const ResumeDetail = lazy(() => import("../pages/ResumeDetail/index"));
const CreateProject = lazy(() => import("../pages/CreateProject/index"));
const CreateResume = lazy(() => import("../pages/CreateResume/index"))
const Members = lazy(() => import("../pages/Members/index"));
const AddMember = lazy(() => import("../pages/AddMember/index"));
const MemberDetail = lazy(() => import("../pages/MemberDetail/index"));
const Mine = lazy(() => import("../pages/Mine/index"));
const CRM_404 = lazy(() => import("../pages/CRM_404/index"));
const ProjectDetail = lazy(() => import("../pages/ProjectDetail/index"));
const ProjectResumeDetail = lazy(() => import("../pages/ProjectResumeDetail/index"));
const ResumeManage = lazy(() => import("../pages/ResumeManage/index"));
const ResumeManageDetail = lazy(() => import("../pages/ResumeManageDetail/index"));
const DataCenter = lazy(() => import("../pages/DataCenter/index"));

const route: routeParams = {
  path: "/crm/:companyId/",
  redirect: "resume/",
  component: CrmApp,
  children: [
    {
      path: "home/",
      component: Home,
    },
    {
      path: "projects/create/",
      component: CreateProject,
    },
    {
      path: "projects/",
      component: Projects,
    },
    {
      path: "myresume",
      component: MyResume,
      children: [
        {
          path: ":resumeId",
          component: MyResumeDetail
        }
      ]
    },
    {
      path: "datacenter/",
      component: DataCenter,
    },
    {
      path: "create/resume",
      component: CreateResume
    },
    {
      path: "resume/",
      component: Resume,
      children: [
        {
          path: ":resumeId/",
          component: ResumeDetail,
        },
      ],
    },
    {
      path: "manage/",
      component: ResumeManage,
      children: [
        {
          path: ":recordId/candidate/:resumeId/",
          component: ResumeManageDetail,
        },
      ],
    },
    {
      path: "members/add/",
      component: AddMember,
    },
    {
      path: "members/",
      component: Members,
    },
    {
      path: "members/:memberId/",
      component: MemberDetail,
      // redirect: "?key=record",
      children: [
        {
          path: "candidate/:resumeId/",
          component: ResumeDetail,
        },
      ],
    },
    {
      path: "projects/:projectId/",
      component: ProjectDetail,
      children: [
        {
          path: "candidate/:resumeId",
          component: ProjectResumeDetail,
        },
      ],
    },
    {
      path: "mine/",
      component: Mine,
    },
  ],
};
if (route.children)
  route.children.push({
    path: "*",
    component: CRM_404,
  });
export default addRequireAuthForObject(route);
